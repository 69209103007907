/* ========== SIDEBAR ========== */
.sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    justify-content: space-between;
    top: 0;
    left: 0;
    bottom: 0;
    width: 32rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background-color: var(--colorWhite100);
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.sidebar__content {
    height: calc(100vh - 150px);
    overflow-y: auto;
  }
  
  .top-insights-container {
    padding-right: 10px;
  }
  
  .sidebar__content::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  
  .sidebar__content:hover::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  .sidebar__content::-webkit-scrollbar-thumb {
    background-color: var(--colorText10);
    border-radius: 3px;
  }
  
  .sidebar__content::-webkit-scrollbar-track {
    background-color: transparent;
  }

/* ---------- SIDEBAR TOP CONTAINER ---------- */
.top {
    display: flex;
    justify-content: space-between;
    padding: 3rem 2.5rem;
    align-items: center;
}

.logo {
    color: var(--colorPrimary);
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 2.4rem;
    gap: 0.5rem;
}

.logo span {
    color: var(--colorSecondary);
    font-weight: bold;
    cursor: pointer;
    font-size: 1.8rem;
}

.logo span span {
    cursor: pointer;
    color: var(--colorPrimary);
}

/* ---------- SIDEBAR NAV/Insight/Sector CONTAINER ---------- */
.container {
    display: flex;
    flex-direction: column;
    padding-left: 2.5rem;
}

.container__title{
    font-size: 1.2rem;
    font-weight: 300;
    color: var(--colorText70);
}

.container__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.4rem;
    margin-top: 1.4rem;
    width: 25rem;
}

.sector {
    display: inline-block;
    margin-left: 1rem;
    margin-top: 1rem;
    padding: .5rem .8rem;
    font-weight: 300;
    border-radius: .4rem;
    font-size: 1.2rem;
    background-color: rgba(176, 165, 255, 0.1);
    color: rgba(4, 0, 35, 0.8);
    transition: all 0.1s;
    cursor: pointer;
}

.sector:hover {
    background-color: rgba(30, 0, 255, 0.8);
    color: var(--colorWhite);
}

.menu {
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
    font-size: 1.8rem;
    color: var(--colorText70);
    border-radius: 1rem;
    margin: .2rem 0rem;
    cursor: pointer;
}

.insight {
    display: flex;
    align-items: center;
    padding: 1rem 2rem 1rem 0rem;
    border-radius: 1rem;
    margin: .2rem 0rem;
    font-size: 1.2rem;
    cursor: pointer;
}

/* HOVER */
.nav:hover,
.insight:hover,
.menu:hover {
    background-color: var(--colorPrimary);
    color: var(--colorWhite);
}

.active .menu {
    background-color: var(--colorPrimary);
    color: var(--colorWhite);
}

.active .menu__text {
    color: var(--colorWhite);
}

.menu:hover .menu__text {
    color: var(--colorWhite);
}

.insight:hover .insight__txt{
    color: var(--colorWhite);
}

.insight:hover .insight__dot {
    background-color: var(--colorWhite);
}

.insight__dot {
    width: 1rem;
    height: 1rem;
    margin-left: 1rem;  
    background-color: var(--colorPrimary);
    border-radius: .5rem;
}

.insight__dot_shimmer {
    width: 1rem;
    height: 1rem;
    margin-left: 1rem;  
    background-color: var(--colorTransparent);
    border-radius: .5rem;
}

.menu__text,
.insight__txt { 
    color: var(--colorText70);
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: 500;
    width: 18rem;
    display: flex;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    font-size: 1.3rem;
    cursor: pointer;
}

/* ---------- BOTTOM CONTAINER ---------- */
.sidebar__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 3rem;
    font-size: 1.6rem;
    color: var(--colorText70);
}

.logout__txt {
    font-size: 1.4rem;
    color: var(--colorText70);
}

.logout {
    display: flex;
    align-items: center;
    gap: 1rem;
}

@keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: 200px 0;
    }
  }
  
  .insight__item_shimmer {
    animation: shimmer 1.5s infinite linear;
    background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 1rem 2rem 1rem 0rem;
    border-radius: 1rem;
    margin: .5rem 0rem;
    font-size: 1.2rem;
    cursor: pointer;
  }

@media (max-width: 750px) {
    .sidebar {
        display: none;
    }
}