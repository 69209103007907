.select__container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.select {
  max-width: 600px;
}

.select-multi {
  max-width: 600px;
}

.divider {
  display: flex;
  height: 2rem;
  width: 0.1rem;
  border-radius: 2rem;
  background-color: var(--colorText10);
}

.chart__canvas {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.6rem;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  font-size: 2.1rem;
  color: var(--colorText70);
  text-align: center;
}

.chart {
  display: grid;
  grid-template-columns: calc(50% - 2px) calc(50% - 2px);
  grid-gap: 2rem;
}

@media (max-width: 750px) {
  .chart {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .select {
    width: 100%;
  }

  .select-multi {
    width: 100%;
  }

  .select__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .divider {
    display: none;
  }  
}
