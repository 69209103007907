.bar {
  background-color: var(--colorWhite100);
  border-radius: 2rem;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  height: 50vh;
}

.chart__top {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .4rem;
  text-align: center;
}

.pie {
  background-color: var(--colorWhite100);
  border-radius: 2rem;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  height: 50vh;
}

.chart__title {
  color: var(--colorText70);
  font-size: 1.8rem;
  font-weight: 600;
}

@media (max-width: 480px) {
  .bar {
    padding-left: 10px;
    padding-right: 10px;
    height: 30vh;
  }

  .pie {
    padding-left: 10px;
    padding-right: 10px;
    height: 30vh;
  }
}