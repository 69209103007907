.motion__container {
  display: flex;
  gap: 5rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: #fefdf961;
  backdrop-filter: blur(20px);
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.motion {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.motion__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  gap: 2rem;
  color: var(--colorText);
}

.motion__text {
  font-size: 2.4rem;
  text-align: center;
  color: var(--colorText);
}